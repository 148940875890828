import * as React from "react";

import Layout from "../components/layout/layout";
import { Headline } from "../components/layout/typography";

export default function PrivacyPage() {
  return (
    <Layout title="Privacy">
      <div className="relative py-32 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <Headline className="text-7xl tracking-tight font-extrabold sm:text-9xl">
              Privacy /<br />
              Datenschutz
            </Headline>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h1 className="adsimple-312138614">Datenschutzerklärung</h1>
            <h2 id="einleitung-ueberblick" className="adsimple-312138614">
              Einleitung und Überblick
            </h2>
            <p>
              Wir haben diese Datenschutzerklärung (Fassung
              22.09.2022-312138614) verfasst, um Ihnen gemäß der Vorgaben der{" "}
              <a
                className="adsimple-312138614"
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312138614#d1e2269-1-1"
                target="_blank"
                rel="noreferrer"
              >
                Datenschutz-Grundverordnung (EU) 2016/679
              </a>{" "}
              und anwendbaren nationalen Gesetzen zu erklären, welche
              personenbezogenen Daten (kurz Daten) wir als Verantwortliche
              &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B.
              Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und
              welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten
              Begriffe sind geschlechtsneutral zu verstehen.
              <br />
              <strong className="adsimple-312138614">Kurz gesagt:</strong> Wir
              informieren Sie umfassend über Daten, die wir über Sie
              verarbeiten.
            </p>
            <p>
              Datenschutzerklärungen klingen für gewöhnlich sehr technisch und
              verwenden juristische Fachbegriffe. Diese Datenschutzerklärung
              soll Ihnen hingegen die wichtigsten Dinge so einfach und
              transparent wie möglich beschreiben. Soweit es der Transparenz
              förderlich ist, werden technische{" "}
              <strong className="adsimple-312138614">
                Begriffe leserfreundlich erklärt
              </strong>
              , Links zu weiterführenden Informationen geboten und{" "}
              <strong className="adsimple-312138614">Grafiken</strong> zum Einsatz
              gebracht. Wir informieren damit in klarer und einfacher Sprache,
              dass wir im Rahmen unserer Geschäftstätigkeiten nur dann
              personenbezogene Daten verarbeiten, wenn eine entsprechende
              gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich,
              wenn man möglichst knappe, unklare und juristisch-technische
              Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn
              es um Datenschutz geht. Ich hoffe, Sie finden die folgenden
              Erläuterungen interessant und informativ und vielleicht ist die
              eine oder andere Information dabei, die Sie noch nicht kannten.
              <br />
              Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die
              unten bzw. im Impressum genannte verantwortliche Stelle zu wenden,
              den vorhandenen Links zu folgen und sich weitere Informationen auf
              Drittseiten anzusehen. Unsere Kontaktdaten finden Sie
              selbstverständlich auch im Impressum.
            </p>
            <h2 id="anwendungsbereich" className="adsimple-312138614">
              Anwendungsbereich
            </h2>
            <p>
              Diese Datenschutzerklärung gilt für alle von uns im Unternehmen
              verarbeiteten personenbezogenen Daten und für alle
              personenbezogenen Daten, die von uns beauftragte Firmen
              (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten
              meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum
              Beispiel Name, E-Mail-Adresse und postalische Anschrift einer
              Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass
              wir unsere Dienstleistungen und Produkte anbieten und abrechnen
              können, sei es online oder offline. Der Anwendungsbereich dieser
              Datenschutzerklärung umfasst:
            </p>
            <ul className="adsimple-312138614">
              <li className="adsimple-312138614">
                alle Onlineauftritte (Websites, Onlineshops), die wir betreiben
              </li>
              <li className="adsimple-312138614">
                Social Media Auftritte und E-Mail-Kommunikation
              </li>
              <li className="adsimple-312138614">
                mobile Apps für Smartphones und andere Geräte
              </li>
            </ul>
            <p>
              <strong className="adsimple-312138614">Kurz gesagt:</strong> Die
              Datenschutzerklärung gilt für alle Bereiche, in denen
              personenbezogene Daten im Unternehmen über die genannten Kanäle
              strukturiert verarbeitet werden. Sollten wir außerhalb dieser
              Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
              gegebenenfalls gesondert informieren.
            </p>
            <h2 id="rechtsgrundlagen" className="adsimple-312138614">
              Rechtsgrundlagen
            </h2>
            <p>
              In der folgenden Datenschutzerklärung geben wir Ihnen transparente
              Informationen zu den rechtlichen Grundsätzen und Vorschriften,
              also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
              ermöglichen, personenbezogene Daten zu verarbeiten.
              <br />
              Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG
              (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27.
              April 2016. Diese Datenschutz-Grundverordnung der EU können Sie
              selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht,
              unter{" "}
              <a
                className="adsimple-312138614"
                href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679"
              >
                https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679
              </a>{" "}
              nachlesen.
            </p>
            <p>
              Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden
              Bedingungen zutrifft:
            </p>
            <ol>
              <li className="adsimple-312138614">
                <strong className="adsimple-312138614">Einwilligung</strong>{" "}
                (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre
                Einwilligung gegeben, Daten zu einem bestimmten Zweck zu
                verarbeiten. Ein Beispiel wäre die Speicherung Ihrer
                eingegebenen Daten eines Kontaktformulars.
              </li>
              <li className="adsimple-312138614">
                <strong className="adsimple-312138614">Vertrag</strong> (Artikel 6
                Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche
                Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre
                Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen
                abschließen, benötigen wir vorab personenbezogene Informationen.
              </li>
              <li className="adsimple-312138614">
                <strong className="adsimple-312138614">
                  Rechtliche Verpflichtung
                </strong>{" "}
                (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen
                Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum
                Beispiel sind wir gesetzlich verpflichtet Rechnungen für die
                Buchhaltung aufzuheben. Diese enthalten in der Regel
                personenbezogene Daten.
              </li>
              <li className="adsimple-312138614">
                <strong className="adsimple-312138614">
                  Berechtigte Interessen
                </strong>{" "}
                (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter
                Interessen, die Ihre Grundrechte nicht einschränken, behalten
                wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen
                zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher
                und wirtschaftlich effizient betreiben zu können. Diese
                Verarbeitung ist somit ein berechtigtes Interesse.
              </li>
            </ol>
            <p>
              Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im
              öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem
              Schutz lebenswichtiger Interessen treten bei uns in der Regel
              nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig
              sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.
            </p>
            <p>
              Zusätzlich zu der EU-Verordnung gelten auch noch nationale
              Gesetze:
            </p>
            <ul className="adsimple-312138614">
              <li className="adsimple-312138614">
                In <strong className="adsimple-312138614">Österreich</strong> ist
                dies das Bundesgesetz zum Schutz natürlicher Personen bei der
                Verarbeitung personenbezogener Daten (
                <strong className="adsimple-312138614">Datenschutzgesetz</strong>),
                kurz <strong className="adsimple-312138614">DSG</strong>.
              </li>
              <li className="adsimple-312138614">
                In <strong className="adsimple-312138614">Deutschland</strong> gilt
                das{" "}
                <strong className="adsimple-312138614">
                  Bundesdatenschutzgesetz
                </strong>
                , kurz<strong className="adsimple-312138614"> BDSG</strong>.
              </li>
            </ul>
            <p>
              Sofern weitere regionale oder nationale Gesetze zur Anwendung
              kommen, informieren wir Sie in den folgenden Abschnitten darüber.
            </p>
            <h2 id="kontaktdaten-verantwortliche" className="adsimple-312138614">
              Kontaktdaten des Verantwortlichen
            </h2>
            <p>
              Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung
              personenbezogener Daten haben, finden Sie nachfolgend die
              Kontaktdaten der verantwortlichen Person bzw. Stelle:
              <br />
              <span className="adsimple-312138614">
                Timothy Krechel
                <br />
                Leostr. 18
                <br />
                53844 Troisdorf, Deutschland
              </span>
              <br />
              <span>
                Vertretungsberechtigt: Timothy Krechel
              </span>
              <br />
              E-Mail: <a href="mailto:privacy@timothy.de">privacy@timothy.de</a>
              <br />
              Telefon: <a href="tel:‭+49 152 28515543‬">‭+49 152 28515543‬</a>
              <br />
              Impressum:{" "}
              <a href="https://www.timothy.de/legal/">
                https://www.timothy.de/legal/
              </a>
            </p>
            <h2 id="speicherdauer" className="adsimple-312138614">
              Speicherdauer
            </h2>
            <p>
              Dass wir personenbezogene Daten nur so lange speichern, wie es für
              die Bereitstellung unserer Dienstleistungen und Produkte unbedingt
              notwendig ist, gilt als generelles Kriterium bei uns. Das
              bedeutet, dass wir personenbezogene Daten löschen, sobald der
              Grund für die Datenverarbeitung nicht mehr vorhanden ist. In
              einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte
              Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern,
              zum Beispiel zu Zwecken der Buchführung.
            </p>
            <p>
              Sollten Sie die Löschung Ihrer Daten wünschen oder die
              Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so
              rasch wie möglich und soweit keine Pflicht zur Speicherung
              besteht, gelöscht.
            </p>
            <p>
              Über die konkrete Dauer der jeweiligen Datenverarbeitung
              informieren wir Sie weiter unten, sofern wir weitere Informationen
              dazu haben.
            </p>
            <h2 id="rechte-dsgvo" className="adsimple-312138614">
              Rechte laut Datenschutz-Grundverordnung
            </h2>
            <p>
              Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden
              Rechte, die Ihnen zustehen, damit es zu einer fairen und
              transparenten Verarbeitung von Daten kommt:
            </p>
            <ul className="adsimple-312138614">
              <li className="adsimple-312138614">
                Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob
                wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie
                Recht darauf eine Kopie der Daten zu erhalten und die folgenden
                Informationen zu erfahren:
                <ul className="adsimple-312138614">
                  <li className="adsimple-312138614">
                    zu welchem Zweck wir die Verarbeitung durchführen;
                  </li>
                  <li className="adsimple-312138614">
                    die Kategorien, also die Arten von Daten, die verarbeitet
                    werden;
                  </li>
                  <li className="adsimple-312138614">
                    wer diese Daten erhält und wenn die Daten an Drittländer
                    übermittelt werden, wie die Sicherheit garantiert werden
                    kann;
                  </li>
                  <li className="adsimple-312138614">
                    wie lange die Daten gespeichert werden;
                  </li>
                  <li className="adsimple-312138614">
                    das Bestehen des Rechts auf Berichtigung, Löschung oder
                    Einschränkung der Verarbeitung und dem Widerspruchsrecht
                    gegen die Verarbeitung;
                  </li>
                  <li className="adsimple-312138614">
                    dass Sie sich bei einer Aufsichtsbehörde beschweren können
                    (Links zu diesen Behörden finden Sie weiter unten);
                  </li>
                  <li className="adsimple-312138614">
                    die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben
                    haben;
                  </li>
                  <li className="adsimple-312138614">
                    ob Profiling durchgeführt wird, ob also Daten automatisch
                    ausgewertet werden, um zu einem persönlichen Profil von
                    Ihnen zu gelangen.
                  </li>
                </ul>
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der
                Daten, was bedeutet, dass wir Daten richtig stellen müssen,
                falls Sie Fehler finden.
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht
                auf Vergessenwerden“), was konkret bedeutet, dass Sie die
                Löschung Ihrer Daten verlangen dürfen.
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
                Verarbeitung, was bedeutet, dass wir die Daten nur mehr
                speichern dürfen aber nicht weiter verwenden.
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 20 DSGVO das Recht auf
                Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage
                Ihre Daten in einem gängigen Format zur Verfügung stellen.
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches
                nach Durchsetzung eine Änderung der Verarbeitung mit sich
                bringt.
                <ul className="adsimple-312138614">
                  <li className="adsimple-312138614">
                    Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit.
                    e (öffentliches Interesse, Ausübung öffentlicher Gewalt)
                    oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse)
                    basiert, können Sie gegen die Verarbeitung Widerspruch
                    einlegen. Wir prüfen danach so rasch wie möglich, ob wir
                    diesem Widerspruch rechtlich nachkommen können.
                  </li>
                  <li className="adsimple-312138614">
                    Werden Daten verwendet, um Direktwerbung zu betreiben,
                    können Sie jederzeit gegen diese Art der Datenverarbeitung
                    widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                    Direktmarketing verwenden.
                  </li>
                  <li className="adsimple-312138614">
                    Werden Daten verwendet, um Profiling zu betreiben, können
                    Sie jederzeit gegen diese Art der Datenverarbeitung
                    widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                    Profiling verwenden.
                  </li>
                </ul>
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht
                einer ausschließlich auf einer automatisierten Verarbeitung (zum
                Beispiel Profiling) beruhenden Entscheidung unterworfen zu
                werden.
              </li>
              <li className="adsimple-312138614">
                Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das
                heißt, Sie können sich jederzeit bei der Datenschutzbehörde
                beschweren, wenn Sie der Meinung sind, dass die
                Datenverarbeitung von personenbezogenen Daten gegen die DSGVO
                verstößt.
              </li>
            </ul>
            <p>
              <strong className="adsimple-312138614">Kurz gesagt:</strong> Sie haben
              Rechte &#8211; zögern Sie nicht, die oben gelistete
              verantwortliche Stelle bei uns zu kontaktieren!
            </p>
            <p>
              Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
              Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
              Ansprüche in sonst einer Weise verletzt worden sind, können Sie
              sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich
              die Datenschutzbehörde, deren Website Sie unter{" "}
              <a
                className="adsimple-312138614"
                href="https://www.dsb.gv.at/?tid=312138614"
                target="_blank"
                rel="noreferrer"
              >
                https://www.dsb.gv.at/
              </a>{" "}
              finden. In Deutschland gibt es für jedes Bundesland einen
              Datenschutzbeauftragten. Für nähere Informationen können Sie sich
              an die{" "}
              <a
                className="adsimple-312138614"
                href="https://www.bfdi.bund.de/DE/Home/home_node.html"
                target="_blank"
                rel="noreferrer"
              >
                Bundesbeauftragte für den Datenschutz und die
                Informationsfreiheit (BfDI)
              </a>{" "}
              wenden. Für unser Unternehmen ist die folgende lokale
              Datenschutzbehörde zuständig:
            </p>
            <h2 id="sicherheit-datenverarbeitung" className="adsimple-312138614">
              Sicherheit der Datenverarbeitung
            </h2>
            <p>
              Um personenbezogene Daten zu schützen, haben wir sowohl technische
              als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich
              ist, verschlüsseln oder pseudonymisieren wir personenbezogene
              Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so
              schwer wie möglich, dass Dritte aus unseren Daten auf persönliche
              Informationen schließen können.
            </p>
            <p>
              Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch
              Technikgestaltung und durch datenschutzfreundliche
              Voreinstellungen&#8221; und meint damit, dass man sowohl bei
              Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum
              Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen
              setzt. Im Folgenden gehen wir, falls erforderlich, noch auf
              konkrete Maßnahmen ein.
            </p>
            <h2 id="tls-verschluesselung-https" className="adsimple-312138614">
              TLS-Verschlüsselung mit https
            </h2>
            <p>
              TLS, Verschlüsselung und https klingen sehr technisch und sind es
              auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure
              steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten
              abhörsicher im Internet zu übertragen.
              <br />
              Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem
              Browser zu unserem Webserver abgesichert ist &#8211; niemand kann
              &#8220;mithören&#8221;.
            </p>
            <p>
              Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und
              erfüllen den Datenschutz durch Technikgestaltung (
              <a
                className="adsimple-312138614"
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312138614"
                target="_blank"
                rel="noreferrer"
              >
                Artikel 25 Absatz 1 DSGVO
              </a>
              ). Durch den Einsatz von TLS (Transport Layer Security), einem
              Verschlüsselungsprotokoll zur sicheren Datenübertragung im
              Internet, können wir den Schutz vertraulicher Daten sicherstellen.
              <br />
              Sie erkennen die Benutzung dieser Absicherung der Datenübertragung
              am kleinen Schlosssymbol{" "}
              <img
                alt="symbol"
                src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
                width="17"
                height="18"
              />{" "}
              links oben im Browser, links von der Internetadresse (z. B.
              beispielseite.de) und der Verwendung des Schemas https (anstatt
              http) als Teil unserer Internetadresse.
              <br />
              Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen
              wir die Google Suche nach &#8220;Hypertext Transfer Protocol
              Secure wiki&#8221; um gute Links zu weiterführenden Informationen
              zu erhalten.
            </p>
            <h2 id="kommunikation" className="adsimple-312138614">
              Kommunikation
            </h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong className="adsimple-312138614">
                      Kommunikation Zusammenfassung
                    </strong>
                    <br />
                    &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail
                    oder Online-Formular kommunizieren
                    <br />
                    &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name,
                    E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu
                    finden Sie bei der jeweils eingesetzten Kontaktart
                    <br />
                    &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden,
                    Geschäftspartnern usw.
                    <br />
                    &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der
                    gesetzlichen Vorschriften
                    <br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a
                    DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag),
                    Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder
              Online-Formular kommunizieren, kann es zur Verarbeitung
              personenbezogener Daten kommen.
            </p>
            <p>
              Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage
              und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die
              Daten während eben solange gespeichert bzw. solange es das Gesetz
              vorschreibt.
            </p>
            <h3 className="adsimple-312138614">Betroffene Personen</h3>
            <p>
              Von den genannten Vorgängen sind alle betroffen, die über die von
              uns bereit gestellten Kommunikationswege den Kontakt zu uns
              suchen.
            </p>
            <h3 className="adsimple-312138614">Telefon</h3>
            <p>
              Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen
              Endgerät und beim eingesetzten Telekommunikationsanbieter
              pseudonymisiert gespeichert. Außerdem können Daten wie Name und
              Telefonnummer im Anschluss per E-Mail versendet und zur
              Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht,
              sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben
              erlauben.
            </p>
            <h3 className="adsimple-312138614">E-Mail</h3>
            <p>
              Wenn Sie mit uns per E-Mail kommunizieren, werden Daten
              gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop,
              Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von
              Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der
              Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.
            </p>
            <h3 className="adsimple-312138614">Online Formulare</h3>
            <p>
              Wenn Sie mit uns mittels Online-Formular kommunizieren, werden
              Daten auf unserem Webserver gespeichert und gegebenenfalls an eine
              E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht,
              sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben
              erlauben.
            </p>
            <h3 className="adsimple-312138614">Rechtsgrundlagen</h3>
            <p>
              Die Verarbeitung der Daten basiert auf den folgenden
              Rechtsgrundlagen:
            </p>
            <ul className="adsimple-312138614">
              <li className="adsimple-312138614">
                Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die
                Einwilligung Ihre Daten zu speichern und weiter für den
                Geschäftsfall betreffende Zwecke zu verwenden;
              </li>
              <li className="adsimple-312138614">
                Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die
                Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder
                einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir
                müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die
                Vorbereitung eines Angebots, verarbeiten;
              </li>
              <li className="adsimple-312138614">
                Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen
                Kundenanfragen und geschäftliche Kommunikation in einem
                professionellen Rahmen betreiben. Dazu sind gewisse technische
                Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und
                Mobilfunkbetreiber notwendig, um die Kommunikation effizient
                betreiben zu können.
              </li>
            </ul>
            <h2 id="webhosting-einleitung" className="adsimple-312138614">
              Webhosting Einleitung
            </h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong className="adsimple-312138614">
                      Webhosting Zusammenfassung
                    </strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website
                    <br />
                    &#x1f91d; Zweck: professionelles Hosting der Website und
                    Absicherung des Betriebs
                    <br />
                    &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des
                    Websitebesuchs, verwendeter Browser und weitere Daten. Mehr
                    Details dazu finden Sie weiter unten bzw. beim jeweils
                    eingesetzten Webhosting Provider.
                    <br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider,
                    aber in der Regel 2 Wochen
                    <br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO
                    (Berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 className="adsimple-312138614">Was ist Webhosting?</h3>
            <p>
              Wenn Sie heutzutage Websites besuchen, werden gewisse
              Informationen &#8211; auch personenbezogene Daten &#8211;
              automatisch erstellt und gespeichert, so auch auf dieser Website.
              Diese Daten sollten möglichst sparsam und nur mit Begründung
              verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit
              aller Webseiten auf einer Domain, d.h. alles von der Startseite
              (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier).
              Mit Domain meinen wir zum Beispiel beispiel.de oder
              musterbeispiel.com.
            </p>
            <p>
              Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone
              ansehen möchten, verwenden Sie dafür ein Programm, das sich
              Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim
              Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple
              Safari. Wir sagen kurz Browser oder Webbrowser dazu.
            </p>
            <p>
              Um die Website anzuzeigen, muss sich der Browser zu einem anderen
              Computer verbinden, wo der Code der Website gespeichert ist: dem
              Webserver. Der Betrieb eines Webservers ist eine komplizierte und
              aufwendige Aufgabe, weswegen dies in der Regel von professionellen
              Anbietern, den Providern, übernommen wird. Diese bieten Webhosting
              an und sorgen damit für eine verlässliche und fehlerfreie
              Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe,
              aber bitte bleiben Sie dran, es wird noch besser!
            </p>
            <p>
              Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer
              (Desktop, Laptop, Tablet oder Smartphone) und während der
              Datenübertragung zu und vom Webserver kann es zu einer
              Verarbeitung personenbezogener Daten kommen. Einerseits speichert
              Ihr Computer Daten, andererseits muss auch der Webserver Daten
              eine Zeit lang speichern, um einen ordentlichen Betrieb zu
              gewährleisten.
            </p>
            <p>
              Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik
              zur Veranschaulichung das Zusammenspiel zwischen Browser, dem
              Internet und dem Hosting-Provider.
            </p>
            <p>
              <img
                
                src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
                alt="Browser und Webserver"
                width="100%"
              />
            </p>
            <h3 className="adsimple-312138614">
              Warum verarbeiten wir personenbezogene Daten?
            </h3>
            <p>Die Zwecke der Datenverarbeitung sind:</p>
            <ol>
              <li className="adsimple-312138614">
                Professionelles Hosting der Website und Absicherung des Betriebs
              </li>
              <li className="adsimple-312138614">
                zur Aufrechterhaltung der Betriebs- und IT-Sicherheit
              </li>
              <li className="adsimple-312138614">
                Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung
                unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung
                von Ansprüchen
              </li>
            </ol>
            <h3 className="adsimple-312138614">Welche Daten werden verarbeitet?</h3>
            <p>
              Auch während Sie unsere Website jetzt gerade besuchen, speichert
              unser Webserver, das ist der Computer auf dem diese Webseite
              gespeichert ist, in der Regel automatisch Daten wie
            </p>
            <ul className="adsimple-312138614">
              <li className="adsimple-312138614">
                die komplette Internetadresse (URL) der aufgerufenen Webseite
              </li>
              <li className="adsimple-312138614">
                Browser und Browserversion (z. B. Chrome 87)
              </li>
              <li className="adsimple-312138614">
                das verwendete Betriebssystem (z. B. Windows 10)
              </li>
              <li className="adsimple-312138614">
                die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z.
                B.{" "}
                <a
                  className="adsimple-312138614"
                  href="https://www.beispielquellsite.de/vondabinichgekommen/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.beispielquellsite.de/vondabinichgekommen/
                </a>
                )
              </li>
              <li className="adsimple-312138614">
                den Hostnamen und die IP-Adresse des Geräts von welchem aus
                zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)
              </li>
              <li className="adsimple-312138614">Datum und Uhrzeit</li>
              <li className="adsimple-312138614">
                in Dateien, den sogenannten Webserver-Logfiles
              </li>
            </ul>
            <h3 className="adsimple-312138614">
              Wie lange werden Daten gespeichert?
            </h3>
            <p>
              In der Regel werden die oben genannten Daten zwei Wochen
              gespeichert und danach automatisch gelöscht. Wir geben diese Daten
              nicht weiter, können jedoch nicht ausschließen, dass diese Daten
              beim Vorliegen von rechtswidrigem Verhalten von Behörden
              eingesehen werden.
            </p>
            <p>
              <strong className="adsimple-312138614">Kurz gesagt:</strong> Ihr
              Besuch wird durch unseren Provider (Firma, die unsere Website auf
              speziellen Computern (Servern) laufen lässt), protokolliert, aber
              wir geben Ihre Daten nicht ohne Zustimmung weiter!
            </p>
            <h3 className="adsimple-312138614">Rechtsgrundlage</h3>
            <p>
              Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im
              Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO
              (Wahrung der berechtigten Interessen), denn die Nutzung von
              professionellem Hosting bei einem Provider ist notwendig, um das
              Unternehmen im Internet sicher und nutzerfreundlich präsentieren
              und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu
              können.
            </p>
            <p>
              Zwischen uns und dem Hosting-Provider besteht in der Regel ein
              Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der
              die Einhaltung von Datenschutz gewährleistet und Datensicherheit
              garantiert.
            </p>
            <h2 id="netlify-datenschutzerklaerung" className="adsimple-312138614">
              Netlify Datenschutzerklärung
            </h2>
            <p>
              Wir verwenden für unsere Website den Webhosting-Anbieter Netlify.
              Dienstanbieter ist das amerikanische Unternehmen Netlify Inc.,
              2325 3rd Street, Suite 29, San Francisco, CA 94104, USA.
            </p>
            <p>
              Netlify verarbeitet Daten von Ihnen u.a. auch in den USA. Wir
              weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs
              derzeit kein angemessenes Schutzniveau für den Datentransfer in
              die USA besteht. Dies kann mit verschiedenen Risiken für die
              Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.
            </p>
            <p>
              Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in
              Drittstaaten (außerhalb der Europäischen Union, Island,
              Liechtenstein, Norwegen, also insbesondere in den USA) oder einer
              Datenweitergabe dorthin verwendet Netlify sogenannte
              Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO).
              Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind
              von der EU-Kommission bereitgestellte Mustervorlagen und sollen
              sicherstellen, dass Ihre Daten auch dann den europäischen
              Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
              beispielsweise in die USA) überliefert und dort gespeichert
              werden. Durch diese Klauseln verpflichtet sich Netlify, bei der
              Verarbeitung Ihrer relevanten Daten, das europäische
              Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA
              gespeichert, verarbeitet und verwaltet werden. Diese Klauseln
              basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie
              finden den Beschluss und die entsprechenden
              Standardvertragsklauseln u.a. hier:{" "}
              <a
                className="adsimple-312138614"
                href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
              >
                https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
              </a>
            </p>
            <p>
              Netlify hält einen Vertrag über die Auftragsverarbeitung gemäß
              Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage für
              unsere Kundenbeziehung zu Netlify fungiert. Dieser verweist
              inhaltlich auf die EU-Standardvertragsklauseln. Sie finden ihn
              hier:{" "}
              <a
                className="adsimple-312138614"
                href="https://www.netlify.com/pdf/netlify-dpa.pdf"
                target="_blank"
                rel="noreferrer"
              >
                https://www.netlify.com/pdf/netlify-dpa.pdf
              </a>
            </p>
            <p>
              Mehr über die Daten, die durch die Verwendung von Netlify
              verarbeitet werden, erfahren Sie in der Privacy Policy auf{" "}
              <a
                className="adsimple-312138614"
                href="https://www.netlify.com/privacy/?tid=312138614"
              >
                https://www.netlify.com/privacy/
              </a>
              .
            </p>
            <h2 id="e-mail-marketing-einleitung" className="adsimple-312138614">
              E-Mail-Marketing Einleitung
            </h2>
            <table border="1" cellpadding="15">
              <tbody>
                <tr>
                  <td>
                    <strong className="adsimple-312138614">
                      E-Mail-Marketing Zusammenfassung
                    </strong>
                    <br />
                    &#x1f465; Betroffene: Newsletter-Abonnenten
                    <br />
                    &#x1f91d; Zweck: Direktwerbung per E-Mail, Benachrichtigung
                    über systemrelevante Ereignisse
                    <br />
                    &#x1f4d3; Verarbeitete Daten: Eingegebene Daten bei der
                    Registrierung jedoch mindestens die E-Mail-Adresse. Mehr
                    Details dazu finden Sie beim jeweils eingesetzten
                    E-Mail-Marketing-Tool.
                    <br />
                    &#x1f4c5; Speicherdauer: Dauer des Bestehens des Abonnements
                    <br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a
                    DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO
                    (berechtigte Interessen)
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 className="adsimple-312138614">Was ist E-Mail-Marketing?</h3>
            <p>
              Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die
              Möglichkeit des E-Mail-Marketings. Dabei werden, sofern Sie dem
              Empfang unserer E-Mails bzw. Newsletter zugestimmt haben, auch
              Daten von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist
              ein Teilbereich des Online-Marketings. Dabei werden Neuigkeiten
              oder allgemeine Informationen über ein Unternehmen, Produkte oder
              Dienstleistungen per E-Mail an eine bestimmte Gruppe an Menschen,
              die sich dafür interessieren, gesendet.
            </p>
            <p>
              Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter)
              teilnehmen wollen, müssen Sie sich im Normalfall einfach nur mit
              Ihrer E-Mail-Adresse anmelden. Dafür füllen Sie ein
              Online-Formular aus und senden es ab. Es kann aber auch vorkommen,
              dass wir Sie etwa um Ihre Anrede und Ihren Namen bitten, damit wir
              Sie auch persönlich anschreiben können.
            </p>
            <p>
              Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe
              des sogenannten „Double-Opt-In-Verfahrens“. Nachdem Sie sich für
              unseren Newsletter auf unserer Website angemeldet haben, bekommen
              Sie eine E-Mail, über die Sie die Newsletter-Anmeldung bestätigen.
              So wird sichergestellt, dass Ihnen die E-Mail-Adresse gehört und
              sich niemand mit einer fremden E-Mail-Adresse angemeldet hat. Wir
              oder ein von uns verwendetes Benachrichtigungs-Tool protokolliert
              jede einzelne Anmeldung. Dies ist nötig, damit wir den rechtlich
              korrekten Anmeldevorgang auch nachweisen können. Dabei wird in der
              Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der
              Anmeldebestätigung und Ihre IP-Adresse gespeichert. Zusätzlich
              wird auch protokolliert, wenn Sie Änderungen Ihrer gespeicherten
              Daten vornehmen.
            </p>
            <h3 className="adsimple-312138614">
              Warum nutzen wir E-Mail-Marketing?
            </h3>
            <p>
              Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets
              die wichtigsten Neuigkeiten über unser Unternehmen präsentieren.
              Dafür nutzen wir unter anderem E-Mail-Marketing &#8211; oft auch
              nur &#8220;Newsletter&#8221; bezeichnet &#8211; als wesentlichen
              Bestandteil unseres Online-Marketings. Sofern Sie sich damit
              einverstanden erklären oder es gesetzlich erlaubt ist, schicken
              wir Ihnen Newsletter, System-E-Mails oder andere
              Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den
              Begriff „Newsletter“ verwenden, meinen wir damit hauptsächlich
              regelmäßig versandte E-Mails. Natürlich wollen wir Sie mit unseren
              Newsletter in keiner Weise belästigen. Darum sind wir wirklich
              stets bemüht, nur relevante und interessante Inhalte zu bieten. So
              erfahren Sie etwa mehr über unser Unternehmen, unsere Leistungen
              oder Produkte. Da wir unsere Angebote auch immer verbessern,
              erfahren Sie über unseren Newsletter auch immer, wenn es
              Neuigkeiten gibt oder wir gerade spezielle, lukrative Aktionen
              anbieten. Sofern wir einen Dienstleister, der ein professionelles
              Versand-Tool anbietet, für unser E-Mail-Marketing beauftragen,
              machen wir das, um Ihnen schnelle und sichere Newsletter bieten zu
              können. Zweck unseres E-Mail-Marketings ist grundsätzlich, Sie
              über neue Angebote zu informieren und auch unseren
              unternehmerischen Zielen näher zu kommen.
            </p>
            <h3 className="adsimple-312138614">Welche Daten werden verarbeitet?</h3>
            <p>
              Wenn Sie über unsere Website Abonnent unseres Newsletters werden,
              bestätigen Sie per E-Mail die Mitgliedschaft in einer
              E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse können auch Ihre
              Anrede, Ihr Name, Ihre Adresse und Ihre Telefonnummer gespeichert
              werden. Allerdings nur, wenn Sie dieser Datenspeicherungen
              zustimmen. Die als solche markierten Daten sind notwendig, damit
              Sie an dem angebotenen Dienst teilnehmen können. Die Angabe ist
              freiwillig, die Nichtangabe führt jedoch dazu, dass Sie den Dienst
              nicht nutzen können. Zusätzlich können etwa auch Informationen zu
              Ihrem Gerät oder zu Ihren bevorzugten Inhalten auf unserer Website
              gespeichert werden. Mehr zur Speicherung von Daten, wenn Sie eine
              Website besuchen, finden Sie im Abschnitt &#8220;Automatische
              Datenspeicherung&#8221;. Ihre Einwilligungserklärung zeichnen wir
              auf, damit wir stets nachweisen können, dass dieser unseren
              Gesetzen entspricht.
            </p>
            <h3 className="adsimple-312138614">Dauer der Datenverarbeitung</h3>
            <p>
              Wenn Sie Ihre E-Mail-Adresse aus unserem
              E-Mail/Newsletter-Verteiler austragen, dürfen wir Ihre Adresse bis
              zu drei Jahren auf Grundlage unserer berechtigten Interessen
              speichern, damit wir Ihre damalige Einwilligung noch nachweisen
              können. Verarbeiten dürfen wir diese Daten nur, wenn wir uns gegen
              etwaige Ansprüche wehren müssen.
            </p>
            <p>
              Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur
              Newsletter-Anmeldung gegeben haben, können Sie jederzeit einen
              individuellen Löschantrag stellen. Widersprechen Sie der
              Einwilligung dauerhaft, behalten wir uns das Recht vor, Ihre
              E-Mail-Adresse in einer Sperrliste zu speichern. Solange Sie
              freiwillig unseren Newsletter abonniert haben, solange behalten
              wir selbstverständlich auch Ihre E-Mail-Adresse.
            </p>
            <h3 className="adsimple-312138614">Widerspruchsrecht</h3>
            <p>
              Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu
              kündigen. Dafür müssen Sie lediglich Ihre Einwilligung zur
              Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur
              wenige Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie
              direkt am Ende jeder E-Mail einen Link, um das
              Newsletter-Abonnement zu kündigen. Wenn der Link im Newsletter
              wirklich nicht zu finden ist, kontaktieren Sie uns bitte per Mail
              und wir werden Ihr Newsletter-Abo unverzüglich kündigen.
            </p>
            <h3 className="adsimple-312138614">Rechtsgrundlage</h3>
            <p>
              Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer{" "}
              <strong className="adsimple-312138614">Einwilligung</strong> (Artikel
              6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen Ihnen nur dann einen
              Newsletter schicken, wenn Sie sich zuvor aktiv dafür angemeldet
              haben. Gegebenenfalls können wir Ihnen auch Werbenachrichten auf
              Grundlage des § 7 Abs. 3 UWG senden, sofern Sie unser Kunde
              geworden sind und der Verwendung ihrer E-Mailadresse für
              Direktwerbung nicht widersprochen haben.
            </p>
            <p>
              Informationen zu speziellen E-Mail-Marketing Diensten und wie
              diese personenbezogene Daten verarbeiten, erfahren Sie &#8211;
              sofern vorhanden &#8211; in den folgenden Abschnitten.
            </p>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p>
              Quelle: Erstellt mit dem{" "}
              <a
                href="https://www.adsimple.de/datenschutz-generator/"
                title="Datenschutz Generator von AdSimple für Deutschland"
              >
                Datenschutz Generator
              </a>{" "}
              von AdSimple
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
